import validatejs from 'validate.js';
import React from 'react';
import { I18nText } from '@wtag/react-comp-lib';
import moment from 'moment';
import { isValidNumber } from 'libphonenumber-js';
import { isFullNameLengthExceeded } from 'sharedWebpack/helpers/travellerValidation';

const ADULT_AGE_LOWER_LIMIT = 12;
const CHILDREN_AGE_LOWER_LIMIT = 2;
const CHILDREN_AGE_UPPER_LIMIT = 11;
const INFANT_AGE_UPPER_LIMIT = 2;

validatejs.extend(validatejs.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: value => +moment.utc(value),

  // Input is a unix timestamp
  format: (value, options) => {
    const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  },
});

export function validate(values, constraints) {
  const options = {
    fullMessages: false,
  };
  return validatejs(values, constraints, options) || {};
}

export const orderTitleValidation = {
  orderTitle: {
    presence: {
      message: <I18nText id="ibe.validations.order_title" />,
    },
  },
};

export const presenceValidation = {
  presence: {
    message: <I18nText id="ibe.validations.presence" />,
  },
};

export const presenceValidationForGeolocation = {
  presence: {
    message: <I18nText id="ibe.validations.hotel.geolocation_presence" />,
  },
};

export const dateValidation = params => {
  const isYesterday = moment(params.minDate)
    .add(1, 'days')
    .isSame(moment(), 'day');
  let message;
  if (isYesterday) {
    message = <I18nText id="ibe.validations.today" />;
  } else {
    let minDate = moment(params.minDate).format('DD/MM/YYYY, HH:mm');
    if (!params.showDateWithHours) {
      minDate = moment(params.minDate).format('DD/MM/YYYY');
    }
    message = I18n.t('components.ibe.validations.earliest', { earliest: minDate });
  }
  const isFutureDate = moment(params.selectedDate).isAfter(params.maxDate);
  if (isFutureDate) {
    const maxDate = moment(params.minDate).add(366, 'days');
    message = (
      <span>{I18n.t('components.ibe.validations.latest', { maxDate: maxDate.format('LL') })}</span>
    );
  }

  return {
    datetime: {
      earliest: params.minDate,
      latest: params.maxDate,
      message,
    },
  };
};

export const originDestinationValidation = {
  originDestination: true,
};

validatejs.validators.originDestination = value => {
  if (!value) {
    return null;
  }

  return <I18nText id="ibe.validations.origin_destination" />;
};

export const totalTravelersValidation = (
  adultCount,
  childrenCount,
  infantCount,
  maxTravelerCount = 9,
) => ({
  totalTravelers: {
    adultCount,
    childrenCount,
    infantCount,
    maxTravelerCount,
  },
});

validatejs.validators.totalTravelers = (value, options) => {
  if (!value) return null;

  const totalTravelerCount = options.adultCount + options.childrenCount + options.infantCount;

  if (totalTravelerCount <= options.maxTravelerCount) return null;

  return <I18nText id="ibe.validations.total_travelers" />;
};

export const noDigitValidation = {
  format: {
    pattern: '[^\\d]+|^$',
    message: <I18nText id="ibe.validations.no_digit" />,
  },
};

export const transliterateValidation = {
  transliterate: true,
};

validatejs.validators.transliterate = (value, options, key, attributes) => {
  if (!value) {
    return null;
  }

  const TRANSLITERATERULES = {
    ü: 'ue',
    ö: 'oe',
    ä: 'ae',
    Ä: 'Ae',
    Ö: 'Oe',
    Ü: 'Ue',
  };
  let translatedValue = value;
  Object.keys(TRANSLITERATERULES).forEach(ruleKey => {
    const keyValue = TRANSLITERATERULES[ruleKey];
    translatedValue = translatedValue.split(ruleKey).join(keyValue);
  });

  const myOptions = {
    ...options,
    pattern: '[\\x00-\\x7F]+',
    message: <I18nText id="ibe.validations.invalid_character" />,
  };

  return validatejs.validators.format(translatedValue, myOptions, key, attributes);
};

export const emailWithoutSpecialCharacters = {
  format: {
    pattern: '[^\\+]+|^$',
    message: <I18nText id="ibe.validations.email_custom" />,
  },
};

export const greaterThanOrEqualToValidation = (field, value) => ({
  numericality: {
    greaterThanOrEqualTo: value,
    message: I18n.t('components.ibe.validations.greater_than_or_equal_to', {
      field,
      value,
    }),
  },
});

export const lessThanOrEqualToValidation = (value, message) => ({
  numericality: {
    lessThanOrEqualTo: value,
    message,
  },
});

export const driverAgeValidation = value => ({
  numericality: {
    lessThanOrEqualTo: value,
    message: <I18nText id="ibe.validations.driver_age" />,
  },
});

export const greaterThanAndLessThanOrEqual = params => {
  let message = I18n.t('components.ibe.validations.greater_than', {
    field: params.field,
    value: params.greaterThan + 1,
  });
  if (params.value > params.lessThanOrEqualTo) {
    const value =
      params.fieldWithRespectTo === undefined
        ? params.lessThanOrEqualTo
        : params.fieldWithRespectTo;
    message = I18n.t('components.ibe.validations.less_than_or_equal_to', {
      field: params.field,
      value,
    });
  }

  return {
    numericality: {
      lessThanOrEqualTo: params.lessThanOrEqualTo,
      greaterThan: params.greaterThan,
      message,
    },
  };
};

export const maxLengthValidation = length => ({
  length: {
    maximum: length,
    message: I18n.t('components.ibe.validations.too_long', { length }),
  },
});

export const lengthValidation = (minLength, maxLength) => ({
  length: {
    minimum: minLength,
    maximum: maxLength,
    message: I18n.t('components.ibe.validations.length', { minLength, maxLength }),
  },
});

export const adultBirthdayValidation = {
  adultBirthday: true,
};

validatejs.validators.adultBirthday = value => {
  if (!value) {
    return null;
  }
  const age = new Date().getFullYear() - new Date(value).getFullYear();

  if (age < ADULT_AGE_LOWER_LIMIT) {
    return <I18nText id="ibe.validations.adult_age" />;
  }

  return null;
};

export const childrenBirthdayValidation = {
  childrenBirthday: true,
};

validatejs.validators.childrenBirthday = value => {
  if (!value) {
    return null;
  }
  const age = new Date().getFullYear() - new Date(value).getFullYear();

  if (age < CHILDREN_AGE_LOWER_LIMIT || age > CHILDREN_AGE_UPPER_LIMIT) {
    return <I18nText id="ibe.validations.children_age" />;
  }

  return null;
};

export const infantBirthdayValidation = {
  infantBirthday: true,
};

validatejs.validators.infantBirthday = value => {
  if (!value) {
    return null;
  }
  const age = new Date().getFullYear() - new Date(value).getFullYear();

  if (age > INFANT_AGE_UPPER_LIMIT) {
    return <I18nText id="ibe.validations.infant_age" />;
  }

  return null;
};

export const phoneNumberValidation = {
  phoneNumber: true,
};

export const hourMinuteValidation = {
  hourMinute: true,
};

validatejs.validators.hourMinute = value => {
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  const anytime = 'anytime';
  if ((value && !timeRegex.test(value) && value !== anytime) || value === '') {
    return <I18nText id="ibe.validations.hour_minute" />;
  }
  return null;
};

export const emailValidation = {
  format: {
    pattern: '[\\w+\\-.]+@[a-z\\d\\-]+(\\.[a-z\\d\\-]+)*\\.[a-z]{2,}|^$',
    flags: 'i',
    message: <I18nText id="ibe.validations.email" />,
  },
};

export const formatValidation = {
  lengthWithoutSpaces: true,
};

validatejs.validators.lengthWithoutSpaces = value => {
  if (!value) {
    return null;
  }

  const infoNoSpace = value.replace(/ /g, '');
  const actualLength = infoNoSpace.length;

  if (actualLength <= 1) {
    return <I18nText id="ibe.validations.min_length" />;
  }

  return null;
};

export const birthdayValidation = {
  birthday: true,
};

validatejs.validators.birthday = value => {
  if (!value) {
    return null;
  }

  if (new Date(value) > new Date()) {
    return <I18nText id="ibe.validations.birthday" />;
  }

  return null;
};

export const timeValidation = params => ({
  time: {
    dropOffTime: params.dropOffTime,
    pickUpTime: params.pickUpTime,
    dropOffDate: params.dropOffDate,
    pickUpDate: params.pickUpDate,
  },
});

validatejs.validators.time = (value, options) => {
  let isSameDate = true;
  isSameDate = moment(options.pickUpDate).isSame(options.dropOffDate);
  let message = {};
  if (isSameDate) {
    if (value <= options.pickUpTime) {
      message = I18n.t('components.ibe.car.validations.dropofftime');
      return message;
    }
  }
  return null;
};

export const duplicateValidation = (value, otherValues, message) => ({
  duplicate: {
    value,
    otherValues,
    message,
  },
});

validatejs.validators.duplicate = (value, options) => {
  if (!value) {
    return null;
  }
  if (options.otherValues.filter(otherValue => otherValue === options.value).length > 1) {
    return options.message;
  }

  return null;
};

export const maxAgeValidation = (field, maxAge) => {
  const message = I18n.t(field, {
    scope: 'components.ibe.validations.max_age',
    maxAge,
  });

  return {
    datetime: {
      earliest: moment.utc().subtract(maxAge, 'years'),
      message,
    },
  };
};

export const minAgeValidation = (field, minAge) => {
  const message = I18n.t(field, {
    scope: 'components.ibe.validations.min_age',
    minAge,
  });

  return {
    datetime: {
      latest: moment.utc().subtract(minAge, 'years'),
      message,
    },
  };
};

export const subStringValidation = (value, carrierCode, message) => ({
  subString: {
    value,
    carrierCode,
    message,
  },
});

validatejs.validators.subString = (value, options) => {
  if (!value) {
    return null;
  }

  if (value.includes(options.carrierCode)) {
    return options.message;
  }

  return null;
};

export const childFlightBirthdayValidation = ({ minAge, maxAge }) => {
  const message = I18n.t('components.ibe.validations.child_flight_birthday', { minAge, maxAge });

  return {
    datetime: {
      earliest: moment.utc().subtract(maxAge, 'years'),
      latest: moment.utc().subtract(minAge, 'years'),
      message,
    },
  };
};

export const childOtherBirthdayValidation = {
  childOtherBirthday: true,
};

validatejs.validators.childOtherBirthday = value => {
  if (!value) {
    return null;
  }

  if (new Date().getFullYear() - new Date(value).getFullYear() > 16) {
    return <I18nText id="ibe.validations.child_other_birthday" />;
  }

  return null;
};

export const childOtherBirthdayAgeValidation = childAge => ({
  childOtherBirthdayAge: { childAge },
});

validatejs.validators.childOtherBirthdayAge = (value, options) => {
  if (!value && !options) {
    return null;
  }

  return I18n.t('components.ibe.validations.child_other_birthday_age', {
    childAge: options.childAge,
  });
};

export const birthdayDriverAgeValidation = {
  birthdayDriverAge: true,
};

validatejs.validators.birthdayDriverAge = value => {
  if (!value) {
    return null;
  }

  return <I18nText id="ibe.validations.birthday_driver_age" />;
};

export const identityCardValidityValidation = {
  identiyCardValidity: true,
};

validatejs.validators.identiyCardValidity = value => {
  if (!value) {
    return null;
  }

  if (new Date(value) < new Date()) {
    return <I18nText id="ibe.validations.identification_validity" />;
  } else if (new Date(value).getFullYear() > 2999) {
    return I18n.t('simple_form.options.person.identification_documents.invalid_year');
  }

  return null;
};

validatejs.validators.phoneNumber = value => {
  if (isValidNumber(value)) return null;
  if (validatejs.isEmpty(value)) return null;

  return <I18nText id="ibe.validations.phone_number" />;
};

export const duplicateMailInList = (value, list) => ({
  duplicateValue: {
    value,
    list,
  },
});

validatejs.validators.duplicateValue = (value, options) => {
  if (!value) {
    return null;
  }
  if (options.list.some(item => item.email === options.value)) {
    // TODO: cant find this in json file ?
    return <I18nText id="ibe.confirmation_mail.duplicate_value" />;
  }

  return null;
};

export const fullNameLengthValidation = (firstName, middleName, lastName, namePrefix) => ({
  fullName: {
    firstName,
    middleName,
    lastName,
    namePrefix,
  },
});

validatejs.validators.fullName = (value, options) => {
  if (!value) {
    return null;
  }

  const { namePrefix, firstName, middleName, lastName } = options;
  const nameLengthError = isFullNameLengthExceeded(namePrefix, firstName, middleName, lastName);
  if (!nameLengthError) {
    return null;
  }

  const { firstNameError, lastNameError, fullNameError, fullNameErrorMessage } = nameLengthError;
  if (firstNameError || lastNameError) {
    return null;
  } else if (fullNameError) {
    return fullNameErrorMessage;
  }

  return null;
};
